import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { Impression } from '../types'
import api from '../utils/api'

type DataParams = {
}

// ** Fetch Users
export let fetchData = createAsyncThunk('public-impressions/fetchData', async (params: DataParams) => {
  return await api.publicImpressions()
})

export let slice = createSlice({
  name: 'public-impressions',
  initialState: {
    data: [] as Impression[],
    count: 0,
    isLoading: true,
    params: {},
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchData.fulfilled, (state, action) => {
        console.log(action)
        state.data = action.payload.items
        state.count = action.payload.count
        state.params = action.payload.params
        state.isLoading = false
      })
      .addCase(fetchData.pending, (state, _action) => {
        state.isLoading = true
      })
      .addCase(fetchData.rejected, (state, _action) => {
        state.isLoading = false
      })
  }
})

export default slice.reducer
