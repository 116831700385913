import { Provider } from 'react-redux'
import './App.css'
import MapImpressionsPage from './pages/map-impressions-page'
import { store } from './store'


function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <MapImpressionsPage />
      </div>
    </Provider>
  )
}

export default App
