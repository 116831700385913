import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, Fragment } from 'react'
import { fetchData as fetchImpressions } from '../store/public-impressions'
import { AppDispatch, RootState } from '../store'

import { YMaps, Map, Placemark, ZoomControl, GeolocationControl, Polyline } from '@pbe/react-yandex-maps'
import { DeviceLocation, Device, Impression } from '../types'

import config from '../config'

let ImpressionPinPack = (params: { impressions: Impression[], device?: Device }) => {
  let locations: DeviceLocation[] = params.impressions.flatMap(impression => {
    try {
      return JSON.parse(impression.locationJson)
    } catch {
      return []
    }
  })
  
  return (<Fragment>
    <Polyline geometry={locations.map(l => [l.lat, l.lng])} />
    { params.impressions[0] ? <ImpressionPin impression={params.impressions[0]} device={params.device} /> : '' }
  </Fragment>)
}

let ImpressionPin = (params: { impression: Impression, device?: Device }) => {
  let location: DeviceLocation = JSON.parse(params.impression.locationJson)
  return <Placemark
    key={`ImpressionPin_placemark_${params.impression.id}`}
    geometry={[location.lat, location.lng]}
    defaultProperties={{
      iconContent: params.device?.title,
      iconCaption: params.impression.id,
    }}
    defaultOptions={{
      preset: 'islands#blueVideoIcon',
    }}
  />
}


let TheMap = () => {
  let dispatch = useDispatch()
  let [mapRef, setMapRef] = useState<any>(undefined)
  let [ymapsRef, setYmapsRef] = useState<any>(undefined)
  
  let impressionsStore = useSelector((state: RootState) => state.publicImpressions)

  useEffect(() => {
    if (!ymapsRef || !mapRef || impressionsStore.data.length < 1) { return }

    let points = impressionsStore.data.flatMap(impression => {
      let location: DeviceLocation = JSON.parse(impression.locationJson)
      return [ location.lat, location.lng ]
    })
    
    let bounds: number[][] = ymapsRef.util.bounds.fromPoints(points)
    ymapsRef.util.requireCenterAndZoom(
      mapRef.getType(),
      bounds,
      mapRef.container.getSize(),
    ).then(function (result: any) {
      mapRef.setCenter(result.center, result.zoom);
    })
  }, [dispatch, impressionsStore.data, ymapsRef, mapRef])
  
  return (
    <YMaps query={{ apikey: config.ymapsKey, load: 'util.bounds,util.requireCenterAndZoom' }}>
      <Map
        defaultState={{ center: [55.753215, 37.622504], zoom: 10 }}
        width='100%' height='100%'
        modules={['geoObject.addon.balloon', 'geolocation']}
        instanceRef={(map) => setMapRef(map)}
        onLoad={(ymap) => setYmapsRef(ymap)}
      >
        <ZoomControl />
        <GeolocationControl />
        <ImpressionPinPack
          impressions={impressionsStore.data}
        />
      </Map>
    </YMaps>
  )
}

let MapImpressionsPage = () => {
  let dispatch = useDispatch<AppDispatch>()
  
  useEffect(() => {
    dispatch(fetchImpressions({ }))
  }, [dispatch])
  
  return (
    <div className='map-container'>
      <TheMap />
    </div>
  )
}

export default MapImpressionsPage
